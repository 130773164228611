import request from "../utils/request";
import { getRamdomNum } from "../utils/index";

export function Api_AutoReply(data) {
  return request({
    url: "/strategy/auto-reply",
    method: "post",
    data,
  });
}
export function Api_ResetAutoReply(params) {
  return request({
    url: "/strategy/reset-auto-reply",
    method: "get",
    params,
  });
}
export function Api_OnlineHeart(data) {
  return request({
    url: "/online/heart",
    method: "post",
    data,
  });
}

export function Api_AiChat(data) {
  let sign_num = getRamdomNum(1000, 9999);
  return request({
    // baseURL: "https://test-im.yay.chat/tolink_api/v1/host_api",
    baseURL: import.meta.env.VITE_BASE_AI_URL,
    headers: {
      AI_SK: btoa(sign_num + sessionStorage.getItem("tolink_token")),
      AI_SK_SIGN: sign_num,
      ADMIN_LOGIN_TOKEN:
        "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImI1ZGE1MzRiLTFiZjctNDAwZC1hNTIzLWM2MGNiNzJlYTQwZCJ9.jwAP81F7zGzMMjc-gm0F5GIBm3WvJWjVwUelOYS6EECbu1TJz9Xnl2gU0iLuBeEr_3JAk6-iCpcSSpa2uXtm6A",
      ADMIN_USER_ID: "0079527",
      ADMIN_USER_NAME: "tolink",
    },
    url: "/ai/ai_chat",
    method: "post",
    data,
  });
}

export function Api_AiOpenSession(data) {
  let sign_num = getRamdomNum(1000, 9999);
  return request({
    baseURL: import.meta.env.VITE_BASE_AI_URL,
    headers: {
      AI_SK: btoa(sign_num + sessionStorage.getItem("tolink_token")),
      AI_SK_SIGN: sign_num,
      ADMIN_LOGIN_TOKEN:
        "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImI1ZGE1MzRiLTFiZjctNDAwZC1hNTIzLWM2MGNiNzJlYTQwZCJ9.jwAP81F7zGzMMjc-gm0F5GIBm3WvJWjVwUelOYS6EECbu1TJz9Xnl2gU0iLuBeEr_3JAk6-iCpcSSpa2uXtm6A",
      ADMIN_USER_ID: "0079527",
      ADMIN_USER_NAME: "tolink",
    },

    url: "/ai/open_session",
    method: "post",
    data,
  });
}
export function Api_AiSendMessage(data) {
  let sign_num = getRamdomNum(1000, 9999);
  return request({
    baseURL: import.meta.env.VITE_BASE_AI_URL,
    headers: {
      AI_SK: btoa(sign_num + sessionStorage.getItem("tolink_token")),
      AI_SK_SIGN: sign_num,
      ADMIN_LOGIN_TOKEN:
        "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImI1ZGE1MzRiLTFiZjctNDAwZC1hNTIzLWM2MGNiNzJlYTQwZCJ9.jwAP81F7zGzMMjc-gm0F5GIBm3WvJWjVwUelOYS6EECbu1TJz9Xnl2gU0iLuBeEr_3JAk6-iCpcSSpa2uXtm6A",
      ADMIN_USER_ID: "0079527",
      ADMIN_USER_NAME: "tolink",
    },

    url: "/ai/send_message",
    method: "post",
    data,
  });
}
export function Api_AiResetSession(params) {
  let sign_num = getRamdomNum(1000, 9999);
  return request({
    baseURL: import.meta.env.VITE_BASE_AI_URL,
    headers: {
      AI_SK: btoa(sign_num + sessionStorage.getItem("tolink_token")),
      AI_SK_SIGN: sign_num,
      ADMIN_LOGIN_TOKEN:
        "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImI1ZGE1MzRiLTFiZjctNDAwZC1hNTIzLWM2MGNiNzJlYTQwZCJ9.jwAP81F7zGzMMjc-gm0F5GIBm3WvJWjVwUelOYS6EECbu1TJz9Xnl2gU0iLuBeEr_3JAk6-iCpcSSpa2uXtm6A",
      ADMIN_USER_ID: "0079527",
      ADMIN_USER_NAME: "tolink",
    },

    url: "/ai/reset_session",
    method: "get",
    params,
  });
}

export function Api_Translate(data) {
  return request({
    url: "/translate/tr",
    method: "get",
    params: data,
  });
}

export function Api_TranslateGGToken(data) {
  return request({
    url: "/translate/ggToken",
    method: "get",
    params: data,
  });
}

export function Api_TranslateReport(data) {
  return request({
    url: "/translate/report",
    method: "post",
    data,
  });
}

export function getConfig() {
  return request({
    url: "/group-member/get-config",
    method: "get",
  });
}
export function getMeta(params) {
  return request({
    url: "/fans/get-meta",
    method: "get",
    params,
  });
}
