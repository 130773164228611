import instance from "./request";
import axios, {AxiosError} from "axios";
import {Api_TranslateReport} from "../api";
import bigInt from "big-integer";

export {}

export class GoogleTokenUtil {
    static lastRequestTime :number = 0;
    static  lastErrorTime :number = 0;
    public static checkCanUse():boolean{
        const timestamp: number = new Date().getTime();
        console.log('checkCanUse',this.lastRequestTime)
        if ((timestamp - this.lastRequestTime) < 500){
            return false;
        }
        if ((timestamp - this.lastErrorTime) <  300 * 1000){
            return false;
        }
        return true;
    }

    public  static async translateGoogle(lan:string, text:string):Promise<string> {
        this.lastRequestTime = new Date().getTime();
        try {
            let  token = this.tokenGen(text);
            if (lan === "zh"){
                lan = "zh-cn";
            }
            let rsp = await ggInstance.get(
                'https://translate.googleapis.com/translate_a/single',
                {
                    params:{
                        'client':'t',
                        'sl':'auto',
                        'tl':lan,
                        'hl':lan,
                        'dt':'t',
                        'ie':"UTF-8",
                        'oe':'UTF-8',
                        'otf':'1',
                        'ssel':'0',
                        'tsel':'0',
                        'kc':'7',
                        'tk':token,
                        'q':text
                    }
                }
            );
            if (rsp.status == 200){
                let  result:string = '';
                for (let  i = 0; i < rsp.data[0].length;i++){
                    let  arr = rsp.data[0][i];
                    result += arr[0];
                }
                console.log('result:\n',result)
                return Promise.resolve(result)
            }
        }catch (e) {
            this.lastErrorTime = new Date().getTime();
            Api_TranslateReport({'text':text,'lan':lan,'errorCode':e.code,'errorMessage':e.message})
            return Promise.resolve('');
        }
        this.lastErrorTime = new Date().getTime();
        return Promise.resolve('');
    }

    public static tokenGen(a: string): string {
        let b: string = "406398";

        const d: bigInt.BigInteger[] = [];
        for ( let f = 0; f < a.length; f++) {
            let g = bigInt(a.charCodeAt(f));
            if (g.lesser(bigInt(128))) {
                d.push(g);
            } else {
                 if (g.lesser(bigInt(2048))) {
                     d.push(g.shiftRight(bigInt(6)).or(bigInt(192)));
                } else{
                     let conditions1 = g.and(bigInt(64512)).eq(bigInt(55296));
                     let conditions2 = f + 1 < a.length;
                     if (conditions1 && conditions2 && bigInt(a.charCodeAt(f + 1)).and(bigInt(64512)).eq(bigInt(56320))
                     ) {
                         const high = g.and(bigInt(1023));
                         const low = bigInt(a.charCodeAt(++f)).and(bigInt(1023)) ;
                         g = bigInt(65536).add(high.shiftLeft(bigInt(10)).add(low))
                         d.push(g.shiftRight(bigInt(18)).or(bigInt(240)))
                         d.push(g.shiftRight(bigInt(12)).and(bigInt(63)).or(bigInt(128)))
                     } else {
                         d.push(g.shiftRight(bigInt(12)).or(bigInt(224)))
                     }
                     d.push(g.shiftRight(bigInt(6)).and(bigInt(63)).or(bigInt(128)))
                 }
                d.push(g.and(bigInt(63)).or(bigInt(128)))
            }
        }
        a = b;
        let tempA  = bigInt(parseInt(a));
        for (let e = 0; e < d.length; e++) {
            tempA = tempA.add(d[e]);
                console.log('before',tempA);
                tempA = this.wra(tempA, '+-a^+6');
                console.log('after',tempA);
        }
        tempA = this.wra(tempA, '+-3^+b+-f');
        tempA = tempA.xor(bigInt((561666268 + 1526272306)))
        if (tempA.lesser(bigInt(0))) {
            tempA = tempA.and(bigInt(2147483647)).add(bigInt(2147483648));
        }
        tempA = tempA.mod(bigInt(1E6));
        let temp = tempA.xor(bigInt(parseInt(b)));
        return tempA.toString() + '.' + temp.toString();
    }

    static wra(a: bigInt.BigInteger, b: String): bigInt.BigInteger {
        try {
            let d: bigInt.BigInteger;
            for (let c = 0; c < b.length - 2; c += 3) {
                let  tempD:string = b[c+2];
                let numChatA: number ='a'.charCodeAt(0);
                let numTempD: number = tempD.charCodeAt(0);
                d = numChatA <= numTempD
                    ? bigInt(numTempD - 87)
                    : bigInt(parseInt(tempD));
                if ('+' === b[c + 1]){
                    d = this.unsignedRightShift(a, d);
                }else {
                    d = a.shiftLeft(d);
                }
                if('+' === b[c] ){
                    a = a.add(d).and(bigInt(4294967295));
                }else{
                    a = a.xor(d);
                }
            }
            return a;
        } catch (error) {
            throw error;
        }
    }

    static unsignedRightShift(a: bigInt.BigInteger, b: bigInt.BigInteger): bigInt.BigInteger {
        let m:bigInt.BigInteger;
        if (b.greaterOrEquals(bigInt(32) ) || b.lesser(bigInt(-32))){
            m = b.divide(bigInt(32));
            b = b.minus(m.multiply(bigInt(32)))
        }

        if (b.lesser(bigInt(0))){
            b = b.add(bigInt(32));
        }
        if (b.eq(bigInt(0))){
            let temp = 0x7fffffff;
            let  result1 = a.shiftRight(bigInt(1)).and(bigInt(temp)).multiply(bigInt(2));
            let  result2 = a.shiftRight(b).and(bigInt(1));
            return result1.add(result2);
        }
        if (a.lesser(bigInt(0))){
            a = a.shiftRight(bigInt(1)).add(bigInt(2147483647)).or(bigInt(0x40000000)).shiftRight(b.minus(bigInt(1)));
        }else {
            a = a.shiftRight(b);
        }
        return a;
    }
}


const ggInstance = axios.create({
    // baseURL: import.meta.env.VITE_BASE_URL, //配置固定域名
    // baseURL: "", //配置固定域名
    timeout: 10000
});
