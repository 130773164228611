import axios from 'axios';
import { logger } from '../../lib/logger';
import {CacheTypes, getCache, putCache} from './cacheUtil';
import {Api_Translate} from "../api";
import {GoogleTokenUtil} from "./google_token_util";

enum TranslateLevel {
  low,
  heigh
}

export async function translate(text: string, lang: string, level: TranslateLevel): Promise<string> {
  const freeCountKey = `translateFreeCount-${level}`;
  const freeLengthKey = `translateFreeLength-${level}`;
  const errorCountKey = `translateFreeErrorCount-${level}`;
  const ggCountKey = `translateCount-${level}`;
  const ggLengthKey = `translateLength-${level}`;
  if (GoogleTokenUtil.checkCanUse()){
    let result = await GoogleTokenUtil.translateGoogle(lang,text);
    const count = await getCache(freeCountKey, CacheTypes.CONFIG);
    const length = await getCache(freeLengthKey, CacheTypes.CONFIG);
    let  tempCount = 1;
    let  tempLength = text.length;
    if (count != null){
      tempCount += count;
    }
    if (length != null){
      tempLength += length;
    }
    await putCache(freeCountKey,CacheTypes.CONFIG,tempCount);
    await putCache(freeLengthKey,CacheTypes.CONFIG,tempLength);
    if (result.length > 0){
      return Promise.resolve(result);
    }else {
      const errorCount = await getCache(errorCountKey, CacheTypes.CONFIG);
      let  tempErrorCount = 1;
      if (errorCount != null){
        tempErrorCount += errorCount;
      }
      await putCache(errorCountKey,CacheTypes.CONFIG,tempErrorCount);
    }
  }
  const count = await getCache(ggCountKey, CacheTypes.CONFIG);
  const length = await getCache(ggLengthKey, CacheTypes.CONFIG);
  let  tempCount = 1;
  let  tempLength = text.length;
  if (count != null){
    tempCount += count;
  }
  if (length != null){
    tempLength += length;
  }
  await putCache(ggCountKey,CacheTypes.CONFIG,tempCount);
  await putCache(ggLengthKey,CacheTypes.CONFIG,tempLength);
  return translateWithGoogle(text, lang);
  // if (level == TranslateLevel.heigh) {
  //   return translateWithGoogle(text, lang);
  // } else {
  //   return translateWithNLLB(text, lang);
  // }
}

async function translateWithGoogle(text: string, lang: string): Promise<string> {
  console.log(`Translate with google lang: ${lang}, text: `, text);
  if (text == null || text == undefined || text.trim().length <= 0) return Promise.resolve('');
  // let rsp = await Api_Translate({'lan':lang,'text':text});
  let rsp = await instance.get(
    'https://translation.googleapis.com/language/translate/v2',
    {
      params: {
        'q': text,
        'target': lang,
        'key': 'AIzaSyDaNB7mShi3e2ZM63mERjCK2Bs2tP-XbA0',
        'format': 'text'
      },
    }).catch((e) => {});
  if (rsp && rsp.status == 200) {
    return Promise.resolve(rsp.data.data['translations'][0]['translatedText']);
    // return Promise.resolve(rsp.data);
  } else {
    return Promise.resolve('');
  }
}

async function translateWithNLLB(text: string, lang: string): Promise<string> {
  if (text.trim().length <= 0) return Promise.resolve('');
  let rsp = await instance.get(
    'https://test-im.yay.chat/translate_api/translate',
    {
      params: {
        'text': text,
        'lang': lang
      },
    }).catch((e) => {});
    console.log('Result from nllb is: ', rsp);
  if (rsp && rsp.status == 200) {
    return Promise.resolve(rsp.data);
  } else {
    return Promise.resolve('');
  }
}


// translate the message will send
export async function translateSend(
  text: string, lang: string
  ): Promise<string> {
    if (!getTranslateSendSwitch()) return Promise.resolve('');

    const result = await translate(text, lang,  TranslateLevel.heigh);

    return Promise.resolve(result);
}

// translate the message received
export async function translateReceive(
  chatId: number, msgId: number, text: string, isSend: boolean
  ): Promise<string> {
    if (!getTranslateReceiveSwitch()) return Promise.resolve('');

    const lang = isSend ? 'en' : 'zh';

    const result = await translate(text, lang, TranslateLevel.low);

    logger(`Translated text with msgId: ${msgId}, lang: ${lang}, text: ${text}, result: ${result}`);

    return Promise.resolve(result);
}

export function getTranslateReceiveSwitch(): boolean {
  return '1' === localStorage.getItem('translate-receive');
}

export function getTranslateSendSwitch(): boolean {
  return '1' === localStorage.getItem('translate-send');
}


// 创建axios的对象
const instance = axios.create({
  // baseURL: import.meta.env.VITE_BASE_URL, //配置固定域名
  // baseURL: "", //配置固定域名
  timeout: 10000
});

instance.interceptors.request.use(
  function (config) {
    config.headers["Content-Type"] = "application/json;charset=utf-8;";
    return config;
  },
  function (err) {
    return Promise.request(err);
  }
);
